.logo>img {
  width: 10vw;
  height: 10vw;
}

.mainHeader {
  font-size: 3vh;
  color: #b91787;
  text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
}
.secondaryHeader {
  font-size: 3vh;
  color: #2276cc;
  text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
}

@media screen and (max-width: 600px) {
  .mainHeader {
    font-size: 2vh;
    color: #b91787;
    text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
  }
  .secondaryHeader {
    font-size: 2vh;
    color: #2276cc;
    text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
  }
}