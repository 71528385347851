
$purple1 : #181743;
$purple2 : #730F8E;
$purple-pink:#A21190;
$pink : #EA3281;
$purple-pink1: #CE0682;
$light-pink: #eb6db4;
$fuscia : #f9026d;


.Planet {



}
.planet-container {
  width:200px;
  height:200px;
  padding:20px;
  border-radius:200px;
  background: (linear-gradient(150deg, $fuscia, transparent ,transparent,transparent));
  box-shadow: 10px 10px 500px 180px rgba(0, 0, 0, 0.4);
  animation: shadowAnima ease-in-out  25s infinite;
  animation-delay: 6.5s;
  .shine {
    width: 0;
    height: 0;
    position:absolute;
    border-left: 33px solid transparent;
    border-right: 33px solid transparent;
    border-top: 26px solid $light-pink;
    border-radius:50%;
    transform: rotate(-40deg);
    top: 45px;
    left: 38px;
    &::after {
      content:"";
      position:absolute;
      background: (linear-gradient(190deg,#c90779, #770e8d, #35145a));
      height:160px;
      width:160px;
      border-radius: 100px;
      left: -78px;
      top: -16px;
    }
  } //end of shine


  .planet-ring {
    width:300px;
    height:40px;
    position:absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid $fuscia;
    border-left: 10px solid $fuscia;
    border-right: 10px solid $fuscia;
    border-radius: 100%;
    left: -40px;
    transform: rotate(-30deg);
    top: 80px;



    &::after {

    }
  }

  .planet-ring2 {
    @extend .planet-ring;
    border-top:10px solid rgba(249, 2, 108, 0.7);
    z-index:0;
    box-shadow:  30px 2px 30px 20px rgba(29, 112, 208, 0.30);
    animation: glowingRing ease-in-out  30s infinite;
    animation-delay: 6.5s;
  }
  .planet {
    position: absolute;
    width:200px;
    height:200px;
    background: linear-gradient(150deg, $fuscia, $purple2, transparent);
    border-radius: 100px;
    box-shadow: inset 20px 2px 30px 2px rgba(29, 12, 208, 0.70);
  } //end of planet
} //end of planet-container


// Planet Animations
@keyframes glowingRing {
  0% {box-shadow:  30px 2px 30px 20px rgba(29, 112, 208, 0.30);}
  50%{box-shadow:  10px 2px 35px 25px rgba(29, 112, 208, 0.95);}
  100%{box-shadow:  30px 2px 30px 20px rgba(29, 112, 208, 0.30);}
}

@keyframes shadowAnima {
  0% {box-shadow: 30px 30px 500px 180px rgba(0, 0, 0, 0.5);}
  50%{box-shadow: 30px 30px 100px 50px rgba(29, 112, 208, 0.30);}
  100%{box-shadow: 30px 30px 500px 180px rgba(0, 0, 0, 0.5);}

}