.ContactMe_main {

  width: 100%;
  //border: 2px solid #e53704;
  z-index: 9;
}

.ContactMe {

  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 70%;
  background: rgb(114, 18, 80, 0.2);
  box-shadow: inset 10px 10px 100px 30px rgba(37, 2, 38, 0.88);
  border: 4px solid rgba(37, 2, 38, 0.88);
  border-radius: 50px;
  margin: 0 auto;
  padding: 20px;
  z-index: 9;

  h1 {
    text-align: center;
      font-size: 5vh;
      color: #b91787;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
  }

  &__inputs {
    opacity: 1;
    text-align: left;
    margin: 25px;

    input {
      margin: 10px;
      width: 100%;
      height: 5vh;
      background: rgba(44, 82, 129, 0.5);
      color: #c384e7;
      font-size: 3vh;
    }
    textarea {
      margin: 10px;
      width: 100%;
      height: 15vh;
      background: rgba(44, 82, 129, 0.5);
      color: #c384e7;
      font-size: 3vh;
      resize: none;
    }
  }

  &__btn {
    margin: 0 auto;
    outline: none;
    text-align: center;
    width: 100%;
    height:2.5vh;

    button {
      text-align: center;
      width: 50%;
      height:3vh;
      font-size: 2vh;
      background: rgb(14, 74, 136);;
      outline: none;
      border-radius: 5px;
      color: #e1e1e1;
      border: 1px solid #730f8e;

      &:hover {
        background: rgba(34, 140, 245, 1);
        color: #4d0738;
      }

    }
  }

}
.earthContainer {
  width: 100vw;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
  .ContactMe {
    display: flex;
    width: 85%;
    h1 {
      text-align: center;
      font-size: 4vh;
      color: #b91787;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
    }
  }
  .earthContainer {
    width: 50vw;

  }
}
