.Description_main {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  z-index: 9;


}

.Description {
  width: 50%;
  background: rgb(114, 18, 80, 0.2);
  box-shadow: inset 10px 10px 100px 30px rgba(37, 2, 38, 0.88);
  border: 4px solid rgba(37, 2, 38, 0.88);
  border-radius: 50px;
  padding: 20px;
  z-index: 9;
  margin-top: 10vh;
}

.aboutMe {
  text-align: center;
  p{
    font-size: 3vh;
    color: #2276cc;
    text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
    text-align: left;
  }
  h1{
    font-size: 4vh;
    color: #b91787;
    text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
  }
}
@media screen and (max-width: 600px) {
  .Description {
    width: 85%;
    }
  .aboutMe {
    text-align: center;
    p{
      font-size: 2.5vh;
      color: #2276cc;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
      text-align: left;
    }
    h1{
      font-size: 3.5vh;
      color: #b91787;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
    }
  }

}

