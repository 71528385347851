.Earth {
  display: flex;
  justify-content: center;
  align-items: center;

}

.earth {
  width: 100%;


  &:before {
    content: "";
    display: block;
    position: absolute;
    background: url('../../assets/earth.jpeg') repeat -50% 0;
    background-size: contain;
    width: 100vw;
    height: 100vw;
    border-radius: 99em;
    transform: rotate(15deg);
    box-shadow: 0 -10px 30px 1px #118cbf ,inset 50px 3px 100px 33px rgb(0,0,0,0.73);
    animation: rotateEarth 120s linear infinite forwards;
    z-index: -1;
  }

  &:after{
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vw;
    border-radius: 99em;
    box-shadow: 0 5px 30px 5px rgba(255,255,255,0.8), inset 50px 3px 100px 23px rgb(0,0,0,0.73);
    background: radial-gradient(rgba(0,0,0,1) , rgba(0,0,0,0.8) ,rgba(0,0,0,0.5) , transparent 100%);
    z-index: -1;
  }
}

// Animations
@keyframes rotateEarth {
  from { background-position: 0 0; }
  to { background-position: 2048px 0; }
}

@media screen and (max-width: 600px) {
  .earth {
    width: 100%;


    &:before {

      box-shadow: 0 -10px 30px 1px #118cbf ,inset 50px 3px 100px 33px rgb(0,0,0,0.73);

    }

    &:after{

      box-shadow: 0 5px 30px 5px rgba(255,255,255,0.8), inset 50px 3px 50px 23px rgb(0,0,0,0.73);
      background: radial-gradient(rgba(0,0,0,0.7) , rgba(0,0,0,0.3) ,rgba(0,0,0,0.1) , transparent 100%);

    }
  }
}