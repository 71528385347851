.Star {
  position: fixed;
  width: 100%;
  height: 400vh;



  .star {
    position: fixed;
    border-radius: 50%;
    background-color: #f9f4a2;
    -webkit-animation-name: glow ;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-iteration-count: infinite;


  }
}

//----------Animations--------
@keyframes glow {
  0% {
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    opacity: 0.75;
    -webkit-transform: scale(1.5);
    transform: scale(1.5) skew(-0.03turn, 5deg);
  }
  50% {
    opacity: 0.8;
    -webkit-transform: scale(2);
    transform: scale(2) skew(-0.1turn, 5deg);
  }
  75% {
    opacity: 0.95;
    -webkit-transform: scale(1.5);
    transform: scale(1.5) skew(-0.03turn, 5deg);
  }
  100% {
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes glowBig {
  0% {
    opacity: 0.8;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    opacity: 0.85;
    -webkit-transform: scale(1.5);
    transform: scale(1.5) ;
  }
  50% {
    opacity: 0.9;
    -webkit-transform: scale(1.9);
    transform: scale(1.2) ;
  }
  75% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5) ;
  }
  100% {
    opacity: 0.8;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}