.LinkButtons {
  z-index: 9;
  left: 0;
  width: 100%;

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .leftButtons {
      display: flex;
    }
    .rightButtons {
      display: flex;
    }
  }
}

.buttonHalo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 7vh;
  height: 7vh;
  border-radius: 50%;
  background: transparent;
  margin: 2vh 0 ;

  a{
    padding: 3vh 3vh;
  }

  .buttonLogo {
    color:  rgb(250,250,250);
  }
}
.facebook {
  border: 7px solid rgb(66, 103, 178);

  .facebookIcon {
    color:  rgb(250,250,250);
  }
}
  .facebook:hover {
    border: 3px dotted  rgb(250,250,250);
    box-shadow: inset 0 0 25px rgba(66, 103, 178, 0.8);
    transition: all 0.5s ease;
    .facebookIcon {
      color: rgb(66, 103, 178);
      text-shadow: 0 0 15px rgb(66, 103, 178);
      transition: all 0.5s ease;
    }

  }
.github{
  border: 7px solid rgb(201, 81, 12);
  .githubIcon {
    color:  rgb(250,250,250);
  }
}
.github:hover {
  border: 3px dotted rgb(201, 81, 12);
  box-shadow: inset 0 0 25px rgb(201, 81, 12);
  transition: all 0.5s ease;
  .githubIcon {
    color: rgb(201, 81, 12);
    text-shadow: 0 0 15px rgb(201, 81, 12);
    transition: all 0.5s ease;
  }

}
.linkedin{
  border: 7px solid rgb(250,250,250);
  .linkedinIcon {
    color: rgb(68, 131, 197)
  }
}
.linkedin:hover {
  border: 3px dotted rgb(66, 103, 178);
  box-shadow: inset 0 0 25px rgb(66, 103, 178);
  transition: all 0.5s ease;
  .linkedinIcon {
    color:  rgb(250,250,250);
    text-shadow: 0 0 15px rgb(66, 103, 178);
    transition: all 0.5s ease;
  }
}
.whatsapp{
  border: 7px solid rgb(37,211,102);
  .whatsappIcon {
    color: rgb(250, 250, 250)
  }
}
.whatsapp:hover {
  border: 3px dotted rgb(27, 162, 78);
  box-shadow: inset 0 0 25px rgba(37, 211, 102, 0.8);
  transition: all 500ms ease;
  .whatsappIcon {
    color: rgb(37,211,102);
    text-shadow: 0 0 15px rgb(37,211,102);
    transition: all 0.5s ease;
  }

}
.resume {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 8px solid rgb(136, 7, 95);
  text-align: center;
  text-decoration: none;

  .resumeIcon {
    color: rgb(250, 250, 250);
  }
}
.resume:hover {
  border: 2px dotted rgb(24, 103, 185);
  box-shadow: inset 0 0 25px rgb(199, 13, 137);
  transition: all 0.5s ease;
  .resumeIcon {
    color: rgb(31, 123, 224);
    text-shadow: 0 0 15px rgb(199, 13, 137);
    transition: all 0.5s ease;
  }
  h6{
    color: rgb(250, 250, 250);

  }

}



@media screen and (max-width: 600px) {
  .buttonsContainer {
    padding: 10px;
    .buttonHalo {
      width: 6vh;
      height: 6vh;
      margin: 4px 5px;

      a {
        padding: 0;
      }
    }
  }
  .icon {
    width: 3vh;
    height: 3vh;
  }
}