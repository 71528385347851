.Skills_main {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  z-index: 9;
}

.Skills {
  width: 50%;
  background: rgb(114, 18, 80, 0.2);
  box-shadow: inset 10px 10px 100px 30px rgba(37, 2, 38, 0.88);
  border: 4px solid rgba(37, 2, 38, 0.88);
  border-radius: 50px;
  margin: 0 auto;
  padding: 20px;
  z-index: 9;
  .node{
    border-radius:3px;
    width:80%;
  }
  .html{
    border-radius:3px;
    width:90%;
  }
  .css{
    border-radius:3px;
    width:90%;
  }
  .react{
    border-radius:3px;
    width:85%;
  }
  .javascript{
    border-radius:3px;
    width:88%;
  }
  .git{
    border-radius:3px;
    width:75%;
  }
  .python{
    border-radius:3px;
    width:55%;
  }
  .express{
    border-radius:3px;
    width:75%;
  }
}

.mySkills {
  text-align: center;
  p{
    font-size: 3vh;
    color: #2276cc;
    text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
    text-align: left;
  }
  h1{
    font-size: 4vh;
    color: #b91787;
    text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
  }
}

.skillsBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  list-style:none;
  padding:0;
  color: #e1e1e1;
  text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
  .skillsBar{
    width:50px;
  }
  .skillsBar li{
    margin:20px 0;
  }
  .bar{
    width: 22.5vw;
    box-sizing: unset;
    background:#940869;
    display:block;
    height:1vh;
    border:1px solid rgba(0,0,0,0.3);
    border-radius:3px;
    overflow:hidden;
    box-shadow:0 0 10px #1669be;
  }
  .bar span{
    height:1vh;
    float:left;
    background:#1669be;
  }
}
@media screen and (max-width: 600px) {
  .Skills {
    width: 85%;
    top: 100vh;
    }
  .mySkills {
    text-align: center;
    p{
      font-size: 2.5vh;
      color: #2276cc;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
      text-align: left;
    }
    h1{
      font-size: 3.5vh;
      color: #b91787;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
    }
  }


}

