.MyWork_main {
  width: 100%;
  z-index: 9;
}
.MyWork {
  line-height: 3.5vh;
  width: 60%;
  background: rgb(114, 18, 80, 0.2);
  box-shadow: inset 10px 10px 100px 30px rgba(37, 2, 38, 0.88);
  border: 4px solid rgba(37, 2, 38, 0.88);
  border-radius: 50px;
  margin: 0 auto;
  padding: 35px;
  z-index: 9;

  &__btn {
    margin: 10px auto;
    outline: none;
    text-align: center;
    width: 100%;
    height: 2.5vh;

    button {
      text-align: center;
      width: 45%;
      height: 3vh;
      font-size: 2vh;
      background: rgb(14, 74, 136);
      outline: none;
      border-radius: 5px;
      color: #e1e1e1;
      border: 1px solid #730f8e;
      margin: 0.5vh 2px;

      &:hover {
        background: rgba(34, 140, 245, 1);
        color: #4d0738;
      }

    }

  }

  .projects {
    text-align: left;

    p {
      font-size: 3vh;
      color: #2276cc;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
      text-align: left;
    }

    h1 {
      font-size: 4.5vh;
      color: #c70d89;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
    }
    h2 {
      font-size: 3.5vh;
      color: #c70d89;
      text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
    }

    .projectHeader {
      text-align: center;
      font-size: 4.5vh;
    }
  }
}
@media screen and (max-width: 600px) {
  .MyWork {
    line-height: 3vh;
    width: 85%;
    padding: 15px;
    &__btn {
      margin:10px auto;
      outline: none;
      text-align: center;
      width: 100%;
      height: 2.5vh;
      }
      button {
        text-align: center;
        width: 40%;
        height: 3vh;
        font-size: 2vh;
        background: rgb(14, 74, 136);
        outline: none;
        border-radius: 5px;
        color: #e1e1e1;
        border: 1px solid #730f8e;
      }
    .projects {
      text-align: left;

      p {
        font-size: 2.5vh;
        color: #2276cc;
        text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
        text-align: left;
      }

      h1 {
        font-size: 3.5vh;
        color: #b91787;
        text-shadow: -1px 0 black, 0 0.5px black, 0.5px 0 black, 0 -1px black;
      }

      .projectHeader {
        text-align: center;
        font-size: 4.5vh;
      }
    }
  }
}
