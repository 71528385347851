.Introduction {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100vw;
  z-index: 9;
}
.centeredPlanet {
  display: flex;
  justify-content: center;
}
.Introduction {
  width: 80vw;
  background: rgb(114, 18, 80, 0.2);
  box-shadow: inset 10px 10px 100px 30px rgba(37, 2, 38, 0.88);
  border: 4px solid rgba(37, 2, 38, 0.88);
  border-radius: 50px;
  margin: 0 auto;
  padding: 1vh 20vw;


  &__logo {
    text-align: center;
    p{
      opacity: 1;
      color: #e19681;
      text-align: left;
    }
    h1{
      opacity: 1;
    }
}



}


@media screen and (max-width: 600px) {
  .Introduction {
    width: 100vw;
  }
  .centeredPlanet {
    margin-top: 0;
  }
}


@media screen and (max-width: 300px) {
  .Introduction {
    width: 100vw;
    margin-top: 20vh;
  }
  .centeredPlanet {
    margin-top: -10vh;
  }
}
